import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from 'components/Image';

const NotFoundPage = () => (
  <>
    <Container>
      <Image fileName='fintom-logo-third.svg' />
      <Row>
        <Col lg={{ span: 6, offset: 2 }} className="text-center">
          <h1>404 NOT FOUND</h1>
          <p>The url is not exist in FINTOM, please check the url carefully, or contact us if you have any problem.</p>
          <a variant="primary" href='/'>Back to Index</a><br />
          <a variant="primary" href='/zh'>返回主頁</a><br />
          <a variant="primary" href='/ja'>戻る</a><br />
        </Col>
      </Row>
    </Container>
  </>
);

export default NotFoundPage;
